import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index.js'
import {translations} from './translations'

const app = createApp(App);

const systemLanguage = navigator.language || navigator.userLanguage;
let defaultLanguage = 'en';

if (systemLanguage.startsWith('ru')) {
    defaultLanguage = 'ru';
}

app.config.globalProperties.$translations = translations;
app.config.globalProperties.$locale = defaultLanguage;

app.config.globalProperties.$t = function (key) {
  return this.$translations[this.$locale][key] || key;
};

app.use(router)
app.mount('#app');