<template>
  <days-on-row-modal :message="dayOnRow.message" v-on:hide-modal="hideDaysOnRowModal" :hide="dayOnRow.hide"></days-on-row-modal>
  <div class="date-header container" id="header">
    <div style="width: auto; overflow-x: auto; ">
      <div style="text-align: center;display: flex;">
        <button v-for="day in days" :class="['day', {'day-active': day.active == true}]" @click="changeActiveDay(day)">
          <span style="font-size: 12px; color: black">{{ day.dayOfWeekName }}</span>
          <div class="day-number">
            {{ day.day }}
          </div>
        </button>
      </div>
    </div>
  </div>

  <input type="hidden" v-model="textTest">
  <div class="container content" :style="{ height: contentHeight + 'px' }">

    <div v-if="tasksLoader == true" style="text-align: center">
      <div class="spinner-border" role="status">
      </div>
    </div>
    <div v-if="tasks.length > 0 && tasksLoader == false">
      <task-item
        v-for="task in tasks"
        v-on:show-update-task-form="setReadTask"
        :task="task"
        :token="token"
        v-on:on-show-day-on-row="showDaysOnRow"
      >
      </task-item>
    </div>
    <div v-else-if="tasks.length == 0 && tasksLoader == false">
      <br>
      <h4>The task list is empty</h4>
    </div>
  </div>
  <div class="offcanvas offcanvas-bottom create-task" tabindex="-1" id="offCanvasReadTask" aria-labelledby="offCanvasReadTaskLabel" style="height: auto;">
    <div class="offcanvas-header" style="padding: 0">
      <h5 style="color: white;text-align: center" id="offCanvasReadTaskLabel">Read task</h5>
      <button type="button" class="btn-close" style="color:white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div style="height:auto">
          <div class="block-content fs-sm">
            <div>
              <b style="color:white">Name</b>
              <input type="text" class="form-control" :value="readTask.title" readonly>
            </div><br>
            <div>
              <b style="color:white">Description</b>
              <textarea
                class="form-control"
                readonly
              >{{ readTask.description }}
              </textarea>
            </div><br>
          </div>
          <div class="mb-3">
            <b style="color:white">Repeat</b>
            <div class="repeat-container">
              <div class="repeat-days-container">
                <span  v-for="day in daysOfWeekName" style="margin-right: 12px">
                  <button  :class="['repeat-day', {'repeat-day-active': checkDay(readTask, day.fullName)}]">
                    {{ day.name }}
                  </button>
                </span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <b style="color:white">points</b>
            </div>
            <div class="col-6" style="text-align: right">
              <b style="color: white">{{ readTask.points }}/10</b>
            </div>
          </div>

          <input type="range" class="form-range" :value="readTask.points" min="1" max="10" id="customRange2">
    </div>
    <div class=" ">
      <button
        v-if="readTask.done == false"
        type="button"
        class="btn btn-sm btn-primary col-12"
        data-bs-dismiss="offcanvas"
        @click="changeStatus(readTask, true)"
        style="margin-bottom: 16px"
      >
        Done
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-lg" viewBox="0 0 16 16">
          <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z"/>
        </svg>
      </button>
      <button type="button" class="btn btn-sm btn-danger col-12" data-bs-dismiss="offcanvas" @click="deleteTask(readTask)">
        Delete task
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
          <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
          <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import TaskItem from '@/components/tasks/TaskItem.vue'
import DaysOnRowModal from '@/components/modals/Modal.vue'

export default {
  name: 'HomeView',
    data() {
      return {
        contentHeight: 0,
        taskDefaultClass: 'task',
        textTest: '',
        tasks: [],
        cart: [],
        readTask: {
          title: '',
          description: '',
          days: [],
        },
        tasksLoader: true,
        taskForm: {
          name: '',
          description: '',  
          points: 10,
          date: ''
        },
        daysOfWeekName: [
          {
            name: 'Sun',
            active: false,
            fullName: 'sunday',
          },
          {
            name: 'Mon',
            active: false,
            fullName: 'monday',
          },
          {
            name: 'Tue',
            active: false,
            fullName: 'tuesday',
          },
          {
            name: 'Wed',
            active: false,
            fullName: 'wednesday',
          },
          {
            name: 'Thu',
            active: false,
            fullName: 'thursday',
          },
          {
            name: 'Fri',
            active: false,
            fullName: 'friday',
          },
          {
            name: 'Sat',
            active: false,
            fullName: 'saturday',
          }
        ],
        days: [],
        dayOnRow: {
          message: '',
          hide: true,
        }
      }
    },
    components: {
      'my-header': Header,
      'task-item': TaskItem,
      'days-on-row-modal': DaysOnRowModal
    },
    async mounted() {
      this.currentDate = this.getCurrentDate();
      this.$emit('change-active-day', this.currentDate.formattedDate);
      this.days = this.getDatesOfCurrentWeek();
      this.setCurrentDateActive(this.days)

      await this.getToken()
      this.getTasks();
    },
    updated() {

      let header = document.getElementById('header')
      let tabber = document.getElementById('tabber');
      this.contentHeight = window.innerHeight - header.offsetHeight - tabber.offsetHeight;
    },
    methods: {
      async getToken() {
        let token = localStorage.getItem('token');

        if (token == null || token == 'undefined') {
          token = await this.generateToken();
        }

        return token;
      },
      generateRandomString(length) {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        
        for (var i = 0; i < charactersLength; i++) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        
        return result;
      },
      async generateToken() {
        await axios.post('http://46.101.199.49/api/generate-token', {
          platform: 'web'
        })
        .then(function (response) {
          let data = response.data;
          localStorage.setItem('token', data.remember_token);
        })

        return localStorage.getItem('token');
      },
      async getTasks() {
        this.tasksLoader = true;
        let activeDay = this.getActiveDay();
        let token = await this.getToken();
        let currentVue = this;
        const config = {
            headers: {
              Authorization: `Bearer ${token}`,
              platform: 'web'
            }
        }

        axios.get('http://46.101.199.49/api/taskByDate?date=' + activeDay.formattedDate, config)
          .then(function (response) {
            currentVue.tasks = response.data;
            currentVue.tasksLoader = false;
          })

        
      },
      getCurrentDate() {
        // Получение текущей даты
        var currentDate = new Date();

        // Получение года
        var year = currentDate.getFullYear();

        // Получение месяца (добавляем 1, так как месяцы в JavaScript начинаются с 0)
        var month = ('0' + (currentDate.getMonth() + 1)).slice(-2);

        // Получение дня
        var day = ('0' + currentDate.getDate()).slice(-2);

        // Форматирование даты в формат YYYY-mm-dd
        let formattedDate = year + '-' + month + '-' + day;

        return {
          formattedDate: formattedDate,
          day: currentDate.getDate()
        };
      },
      getFormattedDate(day) {

        // Получение года
        var year = day.getFullYear();

        // Получение месяца (добавляем 1, так как месяцы в JavaScript начинаются с 0)
        var month = ('0' + (day.getMonth() + 1)).slice(-2);

        // Получение дня
        var day = ('0' + day.getDate()).slice(-2);

        // Форматирование даты в формат YYYY-mm-dd
        return year + '-' + month + '-' + day;
      },
      removeTask(id) {
        let taskCard = document.getElementById('task-card-' + id);
        let filteredTasksList = this.tasks.filter(function(item) {
          return item.id !== id;
        });

        taskCard.classList.add('bounceOutRight')

        this.tasks = filteredTasksList;

        localStorage.setItem('tasks', JSON.stringify(this.tasks))

      },
      async changeStatus(task, status) {
        let token = this.getToken();
        const currentVue = this;
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        }

        await axios.post(
          'http://46.101.199.49/api/task/done',
          {
            id: task.id,
            status: status,
          },
          config
        )
          .then(response => {
            task.done = status;
          })
      },
      // возвращает массив дней текущей недели
      getDatesOfCurrentWeek() {
          const currentDate = new Date();
          const currentDayOfWeek = currentDate.getDay(); // День недели текущей даты (0 - воскресенье, 1 - понедельник, ..., 6 - суббота)
          const firstDayOfWeek = new Date(currentDate); // Копируем текущую дату
          firstDayOfWeek.setDate(currentDate.getDate() - currentDayOfWeek); // Устанавливаем дату на первый день текущей недели

          let dates = [];
          const date = new Date(firstDayOfWeek);
          let day;

          for (let i = 0; i < 7; i++) {
              if (i > 0) {
                date.setDate(date.getDate() + 1);
              }

              let dayOfWeek = new Date(date);
              let formattedDay = ('0' + dayOfWeek.getDate()).slice(-2);

              day = {
                id: this.generateRandomString(),
                day: formattedDay,
                month: dayOfWeek.getMonth(),
                year: dayOfWeek.getFullYear(),
                dayOfWeek: dayOfWeek.getDay(),
                dayOfWeekName: this.daysOfWeekName[dayOfWeek.getDay()].name,
                dayOfWeekFullName: this.daysOfWeekName[dayOfWeek.getDay()].fullName,
                active: false,
                formattedDate: this.getFormattedDate(dayOfWeek)
              };

              dates.push(day);
          }

          console.log(dates);
          return dates;
      },
      setCurrentDateActive(days) {
        let currentDate = new Date();
        let formattedDay = ('0' + currentDate.getDate()).slice(-2);

        for (let i = 0; i < days.length; i++) {
          if (days[i].day == formattedDay) {
            days[i].active = true;
          }
        }
      },
      changeActiveDay(day)
      {
        for (let i = 0; i < this.days.length; i++) {
          if (this.days[i].id == day.id) {
            this.days[i].active = true;
            this.getTasks();
            this.$emit('change-active-day', this.days[i].formattedDate);
          } else if (this.days[i].active == true) {
            this.days[i].active = false;
          }
        }
      },
      getActiveDay()
      {        
        for (let i = 0; i < this.days.length; i++) {
          if (this.days[i].active == true) {
            return this.days[i];
          }
        }
      },
      setReadTask(task)
      {
        this.readTask = task;
      },
      async deleteTask(task)
      {
        let token = this.getToken();
        const currentVue = this;
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        }

        await axios.delete(
          `http://46.101.199.49/api/task/${task.id}`,
          config
        )
          .then(response => {

            currentVue.tasks = currentVue.tasks.filter(item => item.id !== task.id);
          })
          .catch(error =>{

          })
      },
      checkDay(days, day)
      {
        if (!Array.isArray(days)) {
          return false;
        }

        return days.includes(day);
      },
      showDaysOnRow(message)
      {
        this.dayOnRow.message = message;
        this.dayOnRow.hide = false;
      },
      hideDaysOnRowModal() {
        this.dayOnRow.hide = true;
      }
    },
    computed: {
      token() {
        let token = localStorage.getItem('token');

        if (token == null || token == 'undefined') {
          token = this.generateToken();
        }

        return token;
      },
    },
}
</script>

<style>
  .date-header {
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
    background-color: rgba(84, 106, 145);
    z-index: 2;
    width: 100%;
  }
  .day {
    margin: 0 auto;
    padding: 4px 8px 8px 8px;
    border-radius: 12px;
    background-color: rgba(159, 177, 207);
    width: auto;
    color: white;
    margin-right: 8px;
    border: 0;
  }
  .day-active {
    background-color: rgba(62, 80, 114);
  }
  .day-number {
    border-radius: 100%;
    background-color: white;
    color: black;
    width: 32px;
    height: 32px;
    font-size: 12px;
    display: flex;
    align-items: center; /* Центрирование по вертикали */
    justify-content: center; /* Центрирование по горизонтали */
  }
  /* Настройка Offcanvas для заполнения всей высоты */
  .offcanvas-bottom {
    position: fixed;
    bottom: 0;
    z-index: 1050;
    display: flex;
    flex-direction: column;
    max-height: 100vh;
    visibility: hidden;
    overflow-y: auto;
    transition: visibility 0s .3s;
  }
  .offcanvas-bottom.show {
    visibility: visible;
    transition: visibility 0s;
  }

  .task {
      border-radius: 12px;
      width: 100%;
      padding: 12px;
      background-color: rgba(77, 100, 141, 1);
      display: flex;
      margin-bottom: 16px;
  }
  .task-active {
      background-color: rgba(195, 201, 214, 1);
  }
  .task-point {
      border-radius: 100%;
      background-color: rgba(144, 158, 183, 1);
      width: 34px;
      height: 34px;
      display: flex;
      align-items: center; /* Центрирование по вертикали */
      justify-content: center; /* Центрирование по горизонтали */
  }
  .task-name {
      padding-left: 8px;
      display: flex;
      align-items: center; /* Центрирование по вертикали */
  }
  /* Скрыть стандартные чекбоксы */
  .custom-checkbox {
      display: none;
  }

  /* Стилизация кастомных чекбоксов */
  .custom-label {
      display: inline-block;
      width: 25px;
      height: 25px;
      background-color: #fff;
      border: 2px solid #aaa;
      border-radius: 50%; /* Делаем круглый */
      cursor: pointer;
      margin-right: 10px; /* Пространство между чекбоксами */
  }

  /* Галочка при активном состоянии */
  .custom-checkbox:checked + label::before {
      content: "\2713"; /* Unicode символ галочки */
      display: block;
      text-align: center;
      line-height: 25px; /* Центрируем галочку по вертикали */
      color: #000;
  }

  .tabbar {
        bottom: 0;
        width: 100%;
        border-radius: 20px 20px 0 0;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        text-align: center;
        padding: 16px;
    }
    .add-task {
      width: 40px;
      height: 40px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
      border-radius: 100%;
      margin: auto;
      padding-top: 6px;
    }

    .create-task {
      border-radius: 30px 30px 0 0;
      background-color: rgba(77, 100, 141, 1);
      width: 100%;
      padding: 16px;
    }
    .content {
      padding-top: 20px;
      overflow-y: auto;
    }
    .repeat-container {
      border-radius: 12px;
      background-color: rgba(253, 253, 253);
      padding: 12px;
    }
    .repeat-day {
      width: 40px;
      height: 40px;
      background-color: rgba(237, 234, 234, 1);
      border-radius: 100%;
      text-align: center;
      vertical-align: middle;
      display: table-cell;
      font-size: 12px;
      margin-right: 12px;
      border: 0;
      padding: 0;
      color: rgba(0, 0, 0, 1);
    }
    .repeat-day-active {
      background-color: rgb(212, 224, 247);
    }
    .repeat-days-container {
      overflow-x: auto;
      display:flex;
    }
    .task-unactive {
      background-color: rgba(195, 201, 214, 1);
    }
</style>