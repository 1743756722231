<template>
    <div class="grey-background">
        <div class="container">
            <span class="detail-title">Название</span>
            <h3>{{ groupTask.title }}</h3>
            <div style="padding-bottom: 12px">
                <span class="detail-title">Описание</span>
                <div class="detail-desc" v-if="groupTask.description != ''">{{ groupTask.description }}</div>
                <div class="detail-desc" v-else>No desc</div>
            </div>
            <div style="padding-bottom: 12px">
                <span class="detail-title">Очки</span>
                <div class="detail-desc">{{ groupTask.points }}</div>
            </div>
            <span class="detail-title">График выполнения задач</span>
            <div class="buttons-block" style="padding-top: 8px">
                <button v-for="day in groupTask.days" class="btn btn-white" style="margin-right: 16px">
                    {{day}}
                </button>
            </div><br>
            <span class="detail-title">График выполнения задач</span>
            <div>
                <table class="table user-item">
                    <thead>
                        <tr>
                            <th scope="col">name</th>
                            <th scope="col">completed</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="user in usersData">
                            <td>{{user.name}}</td>
                            <td>{{user.count}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'WebViewGroupTaskDetail',
        data () {
            return {
                groupTask: {},
                usersData: [],
            }
        },
        mounted() {
            this.getDetailData();
            this.getUserData();
        },
        methods: {
            getToken() {
                return this.$route.params.token;
            },
            getDetailData()
            {
                let token = this.getToken();
                const currentVue = this;
                const config = {
                    headers: { Authorization: `Bearer ${token}` }
                }

                axios.get('http://46.101.199.49/api/task/' + this.getTaskId(), config)
                    .then(response => {
                        let data = response.data;
                        currentVue.groupTask = data;

                        return ;
                    })
                    .catch( error => {
                        return ;
                    })
            },
            getTaskId()
            {
                return this.$route.params.id;
            },
            getUserData() {

                let token = this.getToken();
                const currentVue = this;
                const config = {
                    headers: { Authorization: `Bearer ${token}` }
                }

                axios.get('http://46.101.199.49/api/task/group/user_data?task_id=' + this.getTaskId(), config)
                    .then(response => {
                        let data = response.data.data;
                        currentVue.usersData = data;

                        return ;
                    })
                    .catch( error => {
                        return ;
                    })
            }
        }
    }
</script>

<style>
    .grey-background {
        width: 100%;
        min-height: 100vh;
        background-color: rgb(246, 249, 255);
        padding: 24px;
    }
    .white-background {
        width: 100%;
        min-height: 100vh;
        background-color: white;
        padding: 24px;
    }
    .detail-title {
        font-weight: 500;
        font-size: 14px;
        color: #5D6B98;
        margin-bottom: 12px;
    }
    .detail-desc {

    }
    .detail-block {
        border-radius: 16px;
        border: 1px solid rgba(234, 236, 240, 1);
        background-color: white;
        padding: 16px;
    }
    .buttons-block {
        display: flex;
        overflow-x: auto;
        white-space: nowrap; /* Для обеспечения нахождения элементов в одной линии */
        width: 100%;
    }
    .btn-white {
        background-color: white;
        color: black;
    }
    .user-item {
        width: 100%;
        padding: 8px;
        background-color: white;
        border-radius: 8px;
        margin-bottom: 16px;
        border: 1px solid rgba(234, 236, 240, 1);
    }
</style>