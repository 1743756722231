<template>
    <div style="height: 250px">
    <canvas id="radarChart" ref="radar_chartdiv" height="150"></canvas>
  </div>
</template>

<script>
import Chart from 'chart.js/auto';

export default {
    name: 'RadarChart',
    mounted () {
        this.setChart();
    },
    props: ['labels', 'label', 'dataset'],
    methods: {
        setChart() {
            let ctx = this.$refs.radar_chartdiv;

            new Chart(ctx, {
                type: 'radar',
                data: {
                    labels: this.labels,
                    datasets: [{
                        label: this.label,
                        data: this.dataset,
                    }]
                },
                options: {
                    elements: {
                        line: {
                            borderWidth: 3
                        }
                    }
                },

            });
        }
    }
}
</script>

<style scoped>
    #radarChart {
        height: 150px;
    }
</style>