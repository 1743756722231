<template>
    
    <div :class="[defaultClass, {'task-unactive': (task.done == true)}]">
          <div
            class="task-point"
            data-bs-toggle="offcanvas"
            data-bs-target="#offCanvasReadTask"
            aria-controls="offCanvasReadTask"
            @click="setReadTask(task)"
          >
              {{ task.points }}
          </div>
          <div
            class="task-name col-7"
            data-bs-toggle="offcanvas"
            data-bs-target="#offCanvasReadTask"
            aria-controls="offCanvasReadTask"
            @click="sendMessage(task)"
          >
              {{ task.title }}
          </div>
          <div class="task-done col-3">
              <div style="display:flex;float:right">
                  <input type="checkbox" :id="'checkbox' + task.id" class="custom-checkbox" v-model="task.done" @change="changeStatus(task.done)">
                  <label :for="'checkbox' + task.id" class="custom-label"></label>
              </div>
          </div>
      </div>
</template>

<script>
    export default {
        name: 'TaskItem',
        data () {
            return {
                defaultClass: 'task ',
            }
        },
        props: {
            task : {
                type: Object,
                required: true
            },
            token: {
                type: String,
                required: true
            }
        },
        methods: {
            async changeStatus(status)
            {
                let token = this.token;
                let task  = this.task;
                const config = {
                    headers: { Authorization: `Bearer ${token}` }
                }

                await axios.post(
                'http://46.101.199.49/api/task/done',
                {
                    id: task.id,
                    status: status,
                },
                config
                )
                .then(response => {
                    let data = response.data;
                    task.done = status;
                    let daysOnRow = data.daysInRow;

                    if (daysOnRow.isFirstToday) {
                        this.$emit('on-show-day-on-row', daysOnRow.daysInRow + ' days on a row!');
                    }
                })
            },
            sendMessage()
            {
                this.$emit('show-update-task-form', this.task)
            }
        },
    }
</script>

<style>
.task {
    border-radius: 12px;
    width: 100%;
    padding: 12px;
    background-color: rgba(77, 100, 141, 1);
    display: flex;
    margin-bottom: 16px;
}
.task-active {
    background-color: rgba(195, 201, 214, 1);
}
.task-point {
    border-radius: 100%;
    background-color: rgba(144, 158, 183, 1);
    width: 34px;
    height: 34px;
    display: flex;
    align-items: center; /* Центрирование по вертикали */
    justify-content: center; /* Центрирование по горизонтали */
}
.task-name {
    padding-left: 8px;
    display: flex;
    align-items: center; /* Центрирование по вертикали */
}
  /* Скрыть стандартные чекбоксы */
  .custom-checkbox {
      display: none;
  }

  /* Стилизация кастомных чекбоксов */
  .custom-label {
      display: inline-block;
      width: 25px;
      height: 25px;
      background-color: #fff;
      border: 2px solid #aaa;
      border-radius: 50%; /* Делаем круглый */
      cursor: pointer;
      margin-right: 10px; /* Пространство между чекбоксами */
  }

  /* Галочка при активном состоянии */
  .custom-checkbox:checked + label::before {
      content: "\2713"; /* Unicode символ галочки */
      display: block;
      text-align: center;
      line-height: 25px; /* Центрируем галочку по вертикали */
      color: #000;
  }

</style scoped>
