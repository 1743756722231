<template>
    <div style="height: 100vh;background-color: #ECF0F8;">
        <div class="profile-header" id="activity-header">
            <h2><b>{{$t('activity')}}</b></h2>
            <div style="display:flex; margin-top: 14px">
                <div class="col-6">
                    <div style="font-size: 14px; width: 100%"><b>{{$t('by_week')}}</b></div>
                    <span style="font-size: 12px" >{{ dateFilterValue }}</span>
                </div>
                <div class="col-6" style="text-align: right">
                    <button class="header-button" @click="setPreWeek">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M14.931 15.9596C15.1642 16.1969 15.165 16.5824 14.9328 16.8207C14.7216 17.0374 14.3906 17.0577 14.1568 16.8814L14.0899 16.8226L9.28193 11.9319C9.06923 11.7155 9.04989 11.3761 9.22389 11.1373L9.28189 11.069L14.0898 6.17745C14.3231 5.94015 14.7005 5.94095 14.9327 6.17924C15.1439 6.39586 15.1625 6.73417 14.9888 6.97218L14.931 7.04035L10.5474 11.5006L14.931 15.9596Z" fill="#040415"/>
                        </svg>
                    </button>
                    <button class="header-button" @click="setNextWeek">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.28193 7.04039C9.04866 6.80311 9.04785 6.41757 9.28011 6.17927C9.49126 5.96263 9.82234 5.94225 10.056 6.11861L10.123 6.17742L14.931 11.0681C15.1437 11.2845 15.163 11.6239 14.989 11.8627L14.931 11.931L10.1231 16.8225C9.88981 17.0598 9.51243 17.059 9.28015 16.8208C9.06898 16.6041 9.05043 16.2658 9.22404 16.0278L9.28189 15.9597L13.6655 11.4994L9.28193 7.04039Z" fill="#040415"/>
                        </svg>

                    </button>
                </div>
            </div>
        </div>
        <div style="background-color: #ECF0F8;width: 100%; height: auto; padding: 16px 8px 76px 8px;overflow-x: auto;" id="activity-container">
            <div class=" col-12 activity-card">
                <div class="activity-card-header">
                    <div class="activity-card-icon">
                        👀
                    </div>
                    <div style="margin-left: 8px">
                        <div class="activity-text-container">
                            <p class="activity-text1">{{ $t('all_time') }}</p>
                            <p class="activity-text2">{{$t('summary')}}</p>
                        </div>
                    </div>
                </div>
                <div style="display:flex; flex-wrap: wrap;margin-top: 12px">
                    <div class="activity-card-block">
                        <p class="activity-card-block-header">{{ $t('completed_percent') }}</p>
                        <p class="activity-card-block-body" style="color: rgba(59, 169, 53, 1);">%{{taskCompletion}}</p>
                    </div>
                    <div class="activity-card-block">
                        <p class="activity-card-block-header">{{ $t('completed') }}</p>
                        <p class="activity-card-block-body" style="color: black;">{{completedCount}}</p>
                    </div>
                    <div class="activity-card-block">
                        <p class="activity-card-block-header">{{$t('points_earned')}}</p>
                        <div class="activity-card-block-body" style="color: rgba(254, 168, 0, 1);">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M4.8407 6.56515C5.67187 5.80016 6.78145 5.33301 8.00014 5.33301C9.21882 5.33301 10.3284 5.80016 11.1596 6.56515L13.2068 2.81186C13.5703 2.14549 13.088 1.33301 12.3289 1.33301H10.118C9.63382 1.33301 9.18766 1.59553 8.9525 2.01882L8.00012 3.73311L7.04773 2.01882C6.81257 1.59553 6.36641 1.33301 5.88219 1.33301H3.67133C2.91227 1.33301 2.42996 2.14549 2.79344 2.81186L4.8407 6.56515Z" fill="#FF9500"/>
                                <path d="M12.6663 9.99967C12.6663 12.577 10.577 14.6663 7.99967 14.6663C5.42235 14.6663 3.33301 12.577 3.33301 9.99967C3.33301 7.42235 5.42235 5.33301 7.99967 5.33301C10.577 5.33301 12.6663 7.42235 12.6663 9.99967Z" fill="#FF9500"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.00033 11.3337C8.73671 11.3337 9.33366 10.7367 9.33366 10.0003C9.33366 9.26395 8.73671 8.66699 8.00033 8.66699C7.26395 8.66699 6.66699 9.26395 6.66699 10.0003C6.66699 10.7367 7.26395 11.3337 8.00033 11.3337Z" fill="#FF9500"/>
                            </svg>

                            {{userScore}}
                        </div>
                    </div>
                    <div class="activity-card-block">
                        <p class="activity-card-block-header">{{$t('streak_day')}}</p>
                        <p class="activity-card-block-body" style="color: black;">{{daysInRowCount}}</p>
                    </div>
                </div>
            </div>
            <div class=" col-12 activity-card">
                <div class="activity-card-header">
                    <div class="activity-card-icon">
                        📈
                    </div>
                    <div style="margin-left: 8px">
                        <div class="activity-text-container">
                            <p class="activity-text1">{{ capitalizeFirstLetter($t('points_earned')) }}</p>
                            <p class="activity-text2">{{ $t('comparison_by_day') }}</p>
                        </div>
                    </div>
                </div>
                <div>

                    <component
                        :is="DailyPointsLineChart"
                        :label="dailyPointsChart.label"
                        :labels="dailyPointsChart.labels"
                        :dataset="dailyPointsChart.dataset"
                        v-if="dailyPointsChart.componentLoaded" 
                    />
                    <div v-else  style="text-align:center;margin-top:50px">
                        <div class="spinner-border text-primary" role="status">
                        </div>
                    </div>
                </div>
            </div>
            <div class=" col-12 activity-card">
                <div class="activity-card-header">
                    <div class="activity-card-icon">
                        ✅
                    </div>
                    <div style="margin-left: 8px">
                        <div class="activity-text-container">
                            <p class="activity-text1">{{$t('repetitive_tasks')}}</p>
                            <p class="activity-text2">{{$t('completed_by_week')}}</p>
                        </div>
                    </div>
                </div>
                <div style="display:flex; flex-wrap: wrap;margin-top: 12px" v-if="repeatTasksLoading">
                    <div  v-for="repeatTask in repeatTasks" class="activity-card-block">
                        <p class="activity-card-block-header">{{repeatTask.name}}</p>
                        <p class="activity-card-block-body" style="color: black;">{{repeatTask.count}}</p>
                    </div>
                </div>
                <div v-else style="text-align: center;margin-top: 12px">
                    <div class="spinner-border text-primary" role="status">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="container" style="margin-bottom: 80px">
        <div style="display: flex;padding: 16px 0">
            <div class="col-6">
                <div class="t-info-card col-11">
                    <span class="t-info-title">
                        {{taskCompletion}} %
                    </span><br>
                    <span class="t-info-desc">
                        task completion
                    </span>
                </div>
            </div>
            <div class="col-6" style="padding-left: auto; display:flex">
                <div class="col-1">
                </div>
                <div class="t-info-card col-11">
                    <span class="t-info-title">
                        {{userScore}}
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star t-info-icon" viewBox="0 0 16 16">
                            <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.56.56 0 0 0-.163-.505L1.71 6.745l4.052-.576a.53.53 0 0 0 .393-.288L8 2.223l1.847 3.658a.53.53 0 0 0 .393.288l4.052.575-2.906 2.77a.56.56 0 0 0-.163.506l.694 3.957-3.686-1.894a.5.5 0 0 0-.461 0z"/>
                        </svg>
                    </span><br>
                    <span class="t-info-desc">
                        points
                    </span>
                </div>
            </div>
        </div>
        <div>
            <div class="card-body">

                <ul class="nav nav-tabs nav-line-tabs fs-6">
                    <li class="nav-item">
                        <a class="nav-link active" data-bs-toggle="tab" href="#kt_tab_pane_1">stats</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" @click="setCharts" data-bs-toggle="tab" href="#kt_tab_pane_2" >charts</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-bs-toggle="tab" @click="getRepeatTasks" href="#kt_tab_pane_3">repeat tasks</a>
                    </li>
                </ul>

                <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active" id="kt_tab_pane_1" role="tabpanel">
                        <table class="table">
                            <thead class="thead-light">
                                <tr>
                                    <th scope="col" colspan="2">Activity number of tasks completed</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>day</td>
                                    <td>{{statistic.userPoints.day}}</td>
                                </tr>
                                <tr>
                                    <td>week</td>
                                    <td>{{statistic.userPoints.week}}</td>
                                </tr>
                                <tr>
                                    <td>month</td>
                                    <td>{{statistic.userPoints.month}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="tab-pane fade container" id="kt_tab_pane_2" role="tabpanel"><br>
                        <div>
                            <h3>Daily Points Balance</h3>
                            <div class="row">
                                <div class="col-6">
                                    <label for="">from</label>
                                    <input
                                        type="date"
                                        v-model="balanceChart.from"
                                        class="form-control"
                                        placeholder="First name"
                                        @change="fetchDataAndLoadUserBalanceComponent"
                                    >
                                </div>
                                <div class="col-6">
                                    <label for="">to</label>
                                    <input
                                        type="date"
                                        v-model="balanceChart.to"
                                        class="form-control"
                                        placeholder="Last name"
                                        @change="fetchDataAndLoadUserBalanceComponent"
                                    >
                                </div>
                            </div>

                            <component
                                :is="BalanceLineChart"
                                :label="balanceChart.label"
                                :labels="balanceChart.labels"
                                :dataset="balanceChart.dataset"
                                v-if="balanceChart.componentLoaded" 
                            />
                            <div v-else  style="text-align:center;margin-top:50px">
                                <div class="spinner-border text-primary" role="status">
                                </div>
                            </div>
                        </div>
                        <div>
                            <h3>Daily Points Earned</h3>
                            <div class="row">
                                <div class="col-6">
                                    <label for="">from</label>
                                    <input
                                        type="date"
                                        v-model="dailyPointsChart.from"
                                        class="form-control"
                                        @change="fetchDataAndLoadUserDailyPointsComponent"
                                    >
                                </div>
                                <div class="col-6">
                                    <label for="">to</label>
                                    <input
                                        type="date"
                                        v-model="dailyPointsChart.to"
                                        class="form-control"
                                        @change="fetchDataAndLoadUserDailyPointsComponent"
                                    >
                                </div>
                            </div>
                            <component
                                :is="DailyPointsLineChart"
                                :label="dailyPointsChart.label"
                                :labels="dailyPointsChart.labels"
                                :dataset="dailyPointsChart.dataset"
                                v-if="dailyPointsChart.componentLoaded" 
                            />
                            <div v-else  style="text-align:center;margin-top:50px">
                                <div class="spinner-border text-primary" role="status">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade container" id="kt_tab_pane_3" role="tabpanel">
                        <table class="table">
                            <tbody>
                                <tr v-for="repeatTask in repeatTasks">
                                    <td>{{repeatTask.name}}</td>
                                    <td>{{repeatTask.count}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
</template>
<script>
    import { defineAsyncComponent, ref } from 'vue'
    import RadarChart from '@/components/charts/RadarChart.vue'

    export default {
        name: 'Profile',
        data() {
            return {
                dailyPointsChart: {
                    label: 'Daily points',
                    labels: [],
                    dataset: [],
                    componentLoaded: false,
                },
                radarChart: {
                    labels: [
                        'Eating',
                        'Drinking',
                        'Sleeping',
                        'Designing',
                        'Coding',
                        'Cycling',
                        'Running'
                    ],
                    label: 'progress',
                    dataset: [65, 59, 90, 81, 56, 55, 40],
                    componentLoaded: false,
                },
                BalanceLineChart: null,
                DailyPointsLineChart: null,
                CategoryStatisticComp: null,
                statistic: {
                    userPoints: {
                        day: 0,
                        week: 0,
                        month: 0,
                    }
                },
                isShowCharts: false,
                userScore: 0,
                repeatTasks: [],
                repeatTasksLoading: false,
                daysInRowCount: 0,
                taskCompletion: 0,
                searchParam: {
                    start: '',
                    end: '',
                },
                searchedBy: 'week',
                currentDate: null,
                dateFilterValue: '',
                completedCount: 0,
            }
        },
        mounted () {
            let header = document.getElementById('activity-header');
            let container = document.getElementById('activity-container');

            container.style.paddingTop = (header.offsetHeight + 16) + 'px';
            this.setCurrentDate(new Date());
            this.setDates();
            this.getCompletedTasks();

            this.getScore();
                this.getDaysInRow();
                this.getCompletion();

            this.initData();
        },
        beforeDestroy() {
        },
        created () {
        },
        methods: {
            setCurrentDate(date) {
                this.currentDate = date;
            },
            getStartAndEndOfWeek(currentDate) {
                const currentDayOfWeek = currentDate.getDay(); // День недели текущей даты (0 - воскресенье, 1 - понедельник, ..., 6 - суббота)
                const firstDayOfWeek = new Date(currentDate); // Копируем текущую дату
                firstDayOfWeek.setDate(currentDate.getDate() - currentDayOfWeek); // Устанавливаем дату на первый день текущей недели

                let dates = [];
                const endDayOfWeek = new Date(firstDayOfWeek);

                // TODO: это нужно переделать
                for (let i = 0; i < 6; i++) {
                    endDayOfWeek.setDate(endDayOfWeek.getDate() + 1);
                }

                return [
                    firstDayOfWeek,
                    endDayOfWeek
                ]
            },
            serializeDateFilterValue() {
                let start = this.serializeDateForFilter(this.searchParam.start);
                let end   = this.serializeDateForFilter(this.searchParam.end);

                return `${start} - ${end}`
            },
            serializeDateForFilter(date) {
                const month = String(date.getMonth()); // добавляем ведущий ноль, если месяц меньше 10
                const day = String(date.getDate()).padStart(2, '0');
                let monthName = this.$t('months')[month];

                return `${monthName} ${day}`;
            },
            serializeDateForRequest(date)
            {
                const year = date.getFullYear();
                const month = String(date.getMonth() + 1).padStart(2, '0'); // добавляем ведущий ноль, если месяц меньше 10
                const day = String(date.getDate()).padStart(2, '0'); 

                return `${year}-${month}-${day}`;
            },
            initData() {
                // this.getUserStatistic();
                this.setCharts();
                this.getRepeatTasks();
            },
            async fetchDataAndLoadUserDailyPointsComponent() {
                this.dailyPointsChart.componentLoaded = false;
                await this.getPointsStatistic();

                const LineChartComp = defineAsyncComponent(() => import('@/components/charts/LineChart.vue'))
                this.dailyPointsChart.componentLoaded = true;
                this.DailyPointsLineChart = LineChartComp;
            },
            getToken() {
                return this.$route.params.token;
            },
            async getPointsStatistic() {
                const config = {
                    headers: { Authorization: `Bearer ${this.getToken()}` }
                }
                let statistic  = [];
                let vue = this;
                let dateFrom = this.serializeDateForRequest(this.searchParam.start);
                let dateEnd = this.serializeDateForRequest(this.searchParam.end);

                await axios.get(`http://46.101.199.49/api/task/statistic/daily_points?start_date=${dateFrom}&end_date=${dateEnd}`, config)
                    .then(response => {
                        statistic = response.data;

                        let labels = [];
                        let dataset = [];

                        for (const i in statistic) {
                            let statItem = statistic[i];
                            labels.push(statItem.d_name)
                            dataset.push(statItem.points)
                        }

                        vue.dailyPointsChart.labels = labels;
                        vue.dailyPointsChart.dataset = dataset;
                    })
            },
            getOneMonthAgoDateString() {
                const today = new Date();
                const oneMonthAgo = new Date(today);
                oneMonthAgo.setMonth(today.getMonth() - 1); // Устанавливаем месяц на один месяц назад

                // Форматируем дату в формат "yyyy-mm-dd"
                const year = oneMonthAgo.getFullYear();
                const month = String(oneMonthAgo.getMonth() + 1).padStart(2, '0'); // добавляем ведущий ноль, если месяц меньше 10
                const day = String(oneMonthAgo.getDate()).padStart(2, '0'); // добавляем ведущий ноль, если день меньше 10

                return `${year}-${month}-${day}`;
            },
            getUserStatistic() {
                const config = {
                    headers: { Authorization: `Bearer ${this.getToken()}` }
                }
                let statistic  = [];
                let vue = this;

                axios.get('http://46.101.199.49/api/user/statistic', config)
                    .then(response => {
                        let data = response.data.avg_tasks;
                        vue.statistic.userPoints.day = data.day;
                        vue.statistic.userPoints.week = data.week;
                        vue.statistic.userPoints.month = data.month;
                    })
            },
            setCharts() {
                if (!this.isShowCharts) {
                    this.fetchDataAndLoadUserDailyPointsComponent();
                    this.isShowCharts = true;
                }
            },
            getScore() {
                const config = {
                    headers: { Authorization: `Bearer ${this.getToken()}` }
                }
                let statistic  = [];
                let vue = this;

                axios.get('http://46.101.199.49/api/user/score', config)
                    .then(response => {
                        let data = response.data;
                        vue.userScore = data.score;
                    })
            },
            getRepeatTasks() {
                const config = {
                    headers: { Authorization: `Bearer ${this.getToken()}` }
                }
                let statistic  = [];
                let vue = this;
                let dateFrom = this.serializeDateForRequest(this.searchParam.start);
                let dateEnd = this.serializeDateForRequest(this.searchParam.end);

                axios.get(`http://46.101.199.49/api/task/repeat_count?start_date=${dateFrom}&end_date=${dateEnd}`, config)
                    .then(response => {
                        vue.repeatTasks = response.data;
                        vue.repeatTasksLoading = true;
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },
            async fetchDataAndLoadCategoryStatisticComponent() {
                this.radarChart.componentLoaded = false;
                await this.getCategoryStatistic();

                const RadarChartComp = defineAsyncComponent(() => import('@/components/charts/RadarChart.vue'))
                this.radarChart.componentLoaded = true;
                this.CategoryStatisticComp = RadarChartComp;
            },
            async getDaysInRow() {
                const config = {
                    headers: { Authorization: `Bearer ${this.getToken()}` }
                }
                let vue = this;

                await axios.get('http://46.101.199.49/api/task/days_in_row', config)
                    .then(response => {
                        vue.daysInRowCount = response.data.length;
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },
            async getCompletion() {
                const config = {
                    headers: { Authorization: `Bearer ${this.getToken()}` }
                }
                let vue = this;

                await axios.get('http://46.101.199.49/api/task/statistic/completion', config)
                    .then(response => {
                        vue.taskCompletion = response.data.percent;
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },
            setDates() {
                let searchParam = this.getStartAndEndOfWeek(this.currentDate);
                this.searchParam.start = searchParam[0];
                this.searchParam.end = searchParam[1];
                this.dateFilterValue = this.serializeDateFilterValue();
            },
            setNextWeek() {
                this.currentDate = this.addDay(this.searchParam.end)
                this.isShowCharts = false;
                this.repeatTasksLoading = false;

                this.setDates();
                this.initData();
            },
            setPreWeek() {
                this.currentDate = this.subDay(this.searchParam.start);
                this.isShowCharts = false;
                this.repeatTasksLoading = false;

                this.setDates();
                this.initData();
            },
            addDay(date) {
                let addDate = new Date(date);
                addDate.setDate(addDate.getDate() + 1);

              return addDate;
            },
            subDay(date) {
                let subDay = new Date(date);
                subDay.setDate(subDay.getDate() - 1);

                return subDay;
            },
            async getCompletedTasks() {
                const config = {
                    headers: { Authorization: `Bearer ${this.getToken()}` }
                }
                let vue = this;

                await axios.get('http://46.101.199.49/api/task/completed_count', config)
                    .then(response => {
                        vue.completedCount = response.data;
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },
            capitalizeFirstLetter(str) {
                if (!str) return ''; // Проверка на пустую строку
                return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
            }
        },
        computed: {
        },
        components: {
            // LineChart
            RadarChart
        }
    }

</script>

<style scoped>
    .hello {
        width: 100%;
        height: 200px;
    }
    .profile-header {
        background-color: white;
        padding: 16px 16px;
        width: 100%;
        position: fixed;
    }
    .t-info-card {
        border: 1.5px solid #4d648d;
        padding: 8px;
        border-radius: 8px;
    }
    .t-info-title {
        font-size: 20px;
        color: #283655;
        font-weight: 600;
    }
    .t-info-desc {
        font-size: 20px;
        color: #283655;
        font-size: 14px;
    }
    .t-info-icon {
        stroke: #283655;
        stroke-width: 1;
    }
    .header-button {
        border: 1px solid #EAECF0;
        background-color: white;
        border-radius: 16px;
        padding: 7.5px;
        width: 40px;
        height: 40px;
        margin-left: 8px;
    }
    .activity-card {
        padding: 12px;
        background-color: white;
        border: 1px solid rgba(234, 236, 240, 1);
        border-radius: 16px;
        box-shadow: 58 26 68 0 rgba(35, 44, 93, 0.06);
        margin-bottom: 8px;
    }
    .activity-card-header {
        display:flex;
    }
    .activity-card-icon {
        width: 36px;
        height: 36px;
        padding: 8px;
        background-color: rgba(243, 244, 246, 1);
        border-radius: 8px;
    }
    .activity-text-container {
        display: flex;
        flex-direction: column;
    }

    .activity-text1, .activity-text2 {
        margin: 0; /* Убираем отступы между текстами */
    }
    .activity-text1 {
        font-weight: 600px;
        font-size: 14px;
        font-weight: bold;
    }
    .activity-text2 {
        color: rgba(155, 155, 161, 1);
        font-size: 12px;
    }
    .activity-card-block {
        width: 50%;
        display: flex;
        flex-direction: column;   
    }
    .activity-card-block-header {
        font-weight: 700;
        font-size: 10px;
        color: rgba(155, 155, 161, 1);
        margin: 0;
    }
    .activity-card-block-body {
        font-size: 18px;
        font-weight: 600;
    }
</style>