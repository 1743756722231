export const translations = {
    en: {
        activity: 'Activity',
        by_week: 'By week',
        all_time: 'All time',
        summary: 'Summary',
        completed_percent: 'COMPLETED PERCENT',
        completed: 'COMPLETED',
        points_earned: 'POINTS EARNED',
        streak_day: 'STREAK DAY',
        comparison_by_day: 'Comparison by day',
        repetitive_tasks: 'Repetitive tasks',
        completed_by_week: 'Completed by week',
        months: [
            'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
            'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ],
        groupMessageTitle: 'Group tasks',
        groupTask: {
            joinToTask: 'Join to task',
            groupTaskCode: 'Task code',
            enterCode: 'Enter code',
            join: 'Join',
            invalidCode: 'invalid code',
        }
    },
    ru: {
        activity: 'Активность',
        by_week: 'По неделям',
        all_time: 'За все время',
        summary: 'Сводка',
        completed_percent: 'ВЫПОЛНЕНО ПРОЦЕНТОВ',
        completed: 'ВЫПОЛНЕНО',
        points_earned: 'ЗАРАБОТАННЫЕ ОЧКИ',
        streak_day: 'СЕРИЯ ДНЕЙ',
        comparison_by_day: 'Сравнение по дням',
        repetitive_tasks: 'Повторяющиеся задачи',
        completed_by_week: 'Завершено за неделю',
        months: [
            'Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн',
            'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'
        ],
        groupMessageTitle: 'Групповые задачи',
        groupTask: {
            joinToTask: 'Присоединиться к задаче',
            groupTaskCode: 'Код задачи',
            enterCode: 'Введите код',
            join: 'Присоединиться',
            invalidCode: 'Не верный код',
        }
    },
}
