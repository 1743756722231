<template>
  <router-view
    @change-active-day="changeExecutionDate"
  />
  <div class="offcanvas offcanvas-bottom create-task" tabindex="-1" id="offcanvasBottom" aria-labelledby="offcanvasBottomLabel" style="height: auto;">
    <div class="offcanvas-header" style="padding: 0">
      <h5 style="color: white;text-align: center" id="offcanvasBottomLabel">New task</h5>
      <button type="button" class="btn-close" style="color:white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div style="height:auto">
          <div class="block-content fs-sm">
            <div>
              <input type="text" class="form-control" v-model="taskForm.name" placeholder="Name your new task">
            </div><br>
            <div>
              <textarea
                v-model="taskForm.description"
                class="form-control"
                placeholder="Describe it"
              >
              </textarea>
            </div><br>
          </div>
          <div>
            <div>
              <b style="color:white">category</b>
            </div>
            <select class="form-select" aria-label="Выберите категорию" v-model="taskForm.category_id">
              <option value="1">select a category</option>
              <option v-for="taskCat in this.taskCategories" :value="taskCat.id">{{ taskCat.name }}</option>
            </select>
          </div><br>
          <div class="mb-3">
            <b style="color:white">Repeat</b>
            <div class="repeat-container">
              <span>Set a cycle for your task</span>
              <hr>
              <div class="repeat-days-container">
                <span  v-for="day in daysOfWeekName" style="margin-right: 12px">
                  <button  :class="['repeat-day', {'repeat-day-active': day.active == true}]" @click="toggleDay(day.name)">
                    {{ day.name }}
                  </button>
                </span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <b style="color:white">points</b>
            </div>
            <div class="col-6" style="text-align: right">
              <b style="color: white">{{ taskForm.points }}/10</b>
            </div>
          </div>

          <input type="range" class="form-range" v-model="taskForm.points" min="0" max="10" step="1" id="customRange2">
    </div>
    <div class="block-content block-content-full text-end">
      <button type="button" class="btn btn-sm btn-primary" data-bs-dismiss="offcanvas" @click="addTask">Создать</button>
    </div>
  </div>

  <div class="tabbar" v-if="$route.name != 'web_view_profile' && $route.name != 'web_view_group_tasks'" style="position:fixed; bottom: 0;z-index:1;background-color: white" id="tabber">
    <div class="container">
      <div class="row">
        <div class="col-4" style="padding:8px">
          <router-link to="/">
            <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15.9844 1.98438C16.5469 1.98438 17.0156 2.1875 17.3906 2.59375C17.7969 2.96875 18 3.4375 18 4V18.0156C18 18.5469 17.7969 19.0156 17.3906 19.4219C17.0156 19.7969 16.5469 19.9844 15.9844 19.9844H2.01562C1.45312 19.9844 0.96875 19.7969 0.5625 19.4219C0.1875 19.0156 0 18.5469 0 18.0156V4C0 3.4375 0.1875 2.96875 0.5625 2.59375C0.96875 2.1875 1.45312 1.98438 2.01562 1.98438H3V0.015625H5.01562V1.98438H12.9844V0.015625H15V1.98438H15.9844ZM15.9844 18.0156V7.98438H2.01562V18.0156H15.9844ZM6 12.0156V10H3.98438V12.0156H6ZM9.98438 12.0156V10H8.01562V12.0156H9.98438ZM14.0156 12.0156V10H12V12.0156H14.0156ZM6 16V13.9844H3.98438V16H6ZM9.98438 16V13.9844H8.01562V16H9.98438ZM14.0156 16V13.9844H12V16H14.0156Z" fill="black" fill-opacity="0.8"/>
            </svg>
          </router-link>
        </div>
        <div class="col-4" style="padding: auto">
          <div
            class="add-task"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasBottom"
            aria-controls="offcanvasBottom"
          >
            <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12.688 7.488H7.84V12.456H5.296V7.488H0.448V5.184H5.296V0.215999H7.84V5.184H12.688V7.488Z" fill="black"/>
            </svg>
          </div>
        </div>
        <div class="col-4" style="padding:8px">
          <router-link to="/profile">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3.70312 12.1406C5.54688 11.3906 7.3125 11.0156 9 11.0156C10.6875 11.0156 12.4375 11.3906 14.25 12.1406C16.0938 12.8594 17.0156 13.8125 17.0156 15V17.0156H0.984375V15C0.984375 13.8125 1.89062 12.8594 3.70312 12.1406ZM11.8125 7.82812C11.0312 8.60938 10.0938 9 9 9C7.90625 9 6.96875 8.60938 6.1875 7.82812C5.40625 7.04688 5.01562 6.10937 5.01562 5.01562C5.01562 3.92188 5.40625 2.98438 6.1875 2.20312C6.96875 1.39063 7.90625 0.984375 9 0.984375C10.0938 0.984375 11.0312 1.39063 11.8125 2.20312C12.5938 2.98438 12.9844 3.92188 12.9844 5.01562C12.9844 6.10937 12.5938 7.04688 11.8125 7.82812Z" fill="black" fill-opacity="0.8"/>
            </svg>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions } from 'vuex';

  export default {
    name: 'App',
    data() {
      return {
        daysOfWeekName: [
          {
            name: 'Sun',
            active: false,
            fullName: 'sunday',
          },
          {
            name: 'Mon',
            active: false,
            fullName: 'monday',
          },
          {
            name: 'Tue',
            active: false,
            fullName: 'tuesday',
          },
          {
            name: 'Wed',
            active: false,
            fullName: 'wednesday',
          },
          {
            name: 'Thu',
            active: false,
            fullName: 'thursday',
          },
          {
            name: 'Fri',
            active: false,
            fullName: 'friday',
          },
          {
            name: 'Sat',
            active: false,
            fullName: 'saturday',
          }
        ],
        taskForm: {
          name: '',
          description: '',  
          points: 10,
          date: '',
          category_id: 1,
        },
        executionDate: null,
        taskCategories: [],
      }
    },

    mounted () {
      // if (this.$route.name != 'web_view_profile') {
        this.getTaskCategories();
      // }
    },
    methods: {
      getToken() {
        let token = localStorage.getItem('token');

        if (token == null || token == 'undefined') {
          token = this.generateToken();
        }

        return token;
      },
      clearTaskForm()
      {
        this.taskForm.name = '';
        this.taskForm.description = '';
        this.taskForm.points = 10;
        this.taskForm.category_id = 1;

        this.resetDayInWeeks();

        return ;
      },
      resetDayInWeeks()
      {
        for (let i = 0; i < this.daysOfWeekName.length; i++) {
          this.daysOfWeekName[i].active = false;
        }
      },
      async addTask() {
        let days = this.daysOfWeekName.filter(obj => obj.active).map(obj => obj.fullName);

        let task = {
          title: this.taskForm.name,
          description: this.taskForm.description,
          done: false,
          point: this.taskForm.points,
          days: days,
          category_id: this.taskForm.category_id,
          execution_date: this.executionDate,
        }
        let token = this.getToken();
        const currentVue = this;
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        }

        await axios.post('http://46.101.199.49/api/task/create', task, config)
          .then(function (response) {
            // TODO: придумать как список обнавлять в списке задач
            location.reload();

            return ;
          })
          .catch( error =>{
          })

        this.clearTaskForm();
      },
      toggleDay(dayName) {
        let day = this.daysOfWeekName.find(function (item) {
          return item.name == dayName;
        })

        if (day.active) {
          day.active = false;
          return ;
        }

        day.active = true;
      },
      async getTaskCategories() {
        let token = this.getToken();
        const currentVue = this;
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        }

        await axios.get('http://46.101.199.49/api/task/category', config)
          .then(function (response) {
            let categories = response.data;
            currentVue.taskCategories = categories;
          })
      },
      changeExecutionDate(executionDate) {
        this.executionDate = executionDate;
      }
    }
  }
</script>
<style>
@font-face {
    font-family: 'MyFont';
    src: '/public/fonts/AirbnbCereal_W_Bd.otf' format('opentype');
    font-weight: normal;
    font-style: normal;
}

/* Использование шрифта */
body {
    font-family: 'MyFont', sans-serif;
}
</style>