<template>

    <router-link :to="'/web-view/group-tasks/' + groupTask.id + '/' + getToken()">
        <div class="group-task-item">
            <div class="row">
                <div class="col-8">
                    <b class="group-task-item-title">{{ groupTask.title }}</b>
                </div>
                <div class="col-4">
                    <div class="friend-icons">
                        <div v-for="(friend, index) in groupTask.members.slice(0, 2)" :key="index" class="icon-wrapper" :style="{ zIndex:  index}">
                            <div class="icon" :style="{backgroundColor: colors[index]}">
                                {{ friend.name[0] ?? 'T' }}
                            </div>
                        </div>
                        <div v-if="groupTask.members.length > 2" style="z-index: 3" class="icon-wrapper">
                            <div class="icon icon-plus">
                                +{{ groupTask.members.length - 2 }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </router-link>
</template>

<script>
    export default {
        name: 'GroupTaskItem',
        props: {
            groupTask: {
                type: Object,
                required: true
            }
        },
        data () {
            return {
                colors: [
                    'rgba(255, 125, 125, 1)',
                    'rgba(18, 164, 237, 1)'
                ],
            }
        },
        methods: {
            getToken() {
                return this.$route.params.token;
            },
        }
    }
</script>

<style scoped>
    .group-task-item {
        border-radius: 16px;
        padding: 12px 16px;
        border: 1px solid rgba(234, 236, 240, 1);
        background-color: white;
    }
    .group-task-item-title {
        font-weight: 600;
        font-size: 14px;
    }
    .friend-icons {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .icon-wrapper {
        margin-left: -16px; /* Adjust this value to control overlap */
    }

    .icon {
        width: 40px;  /* Adjust the size of the icons */
        height: 40px;
        border-radius: 50%;
        border: 4px solid white; /* Optional: Add a border */
        overflow: hidden;
        background-color: rgba(155, 155, 161, 1);
        text-align: center;  display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        color: white;
    }
    .friend-icons-desc {
        color: rgba(155, 155, 161, 1);
        font-weight: 400;
        font-size: 12px;
    }
    .icon-plus {
        background-color: rgba(235, 236, 255, 1);
        color: rgba(56, 67, 255, 1);
    }
    a  {
        text-decoration: none; 
        color: black;
    }
</style>