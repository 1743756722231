<template>
    <div :class="['ho-background', {'ho-hide': hide == true}]">
        <div class="ho-modal-background">
        </div>
        <div class="container" style="z-index: 1;position:absolute;top:20%">
            <div class="ho-modal">
                <div style="text-align:center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="#dc3545" class="bi bi-fire" viewBox="0 0 16 16">
                        <path d="M8 16c3.314 0 6-2 6-5.5 0-1.5-.5-4-2.5-6 .25 1.5-1.25 2-1.25 2C11 4 9 .5 6 0c.357 2 .5 4-2 6-1.25 1-2 2.729-2 4.5C2 14 4.686 16 8 16m0-1c-1.657 0-3-1-3-2.75 0-.75.25-2 1.25-3C6.125 10 7 10.5 7 10.5c-.375-1.25.5-3.25 2-3.5-.179 1-.25 2 1 3 .625.5 1 1.364 1 2.25C11 14 9.657 15 8 15"/>
                    </svg>
                </div>
                <div style="text-align:center;margin-top: 20px">
                    <h1>{{message}}</h1>
                    <p>You're doing a great job, keep up the good work!</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Modal",
    props: ['message', 'hide'],
    mounted () {
        const hoBackground = document.querySelector('.ho-modal-background');

        let vue = this;
        hoBackground.addEventListener('click', (event) => {
            vue.$emit('hide-modal')
        })
    },
    data() {
        return {
        }
    }
}
</script>

<style scoped>
    .ho-hide {
        display: none;
    }
    .ho-modal-background {
      background: black;
      width: 100%;
      height: 100%;
      opacity: 0.5;
    }
    .ho-modal {
      width: 100%;
      background-color: white;
      z-index: 2;
      border-radius: 8px;
      padding: 16px;
    }
    .ho-background {
        z-index: 1;
      position: absolute;
      width: 100%;
      height: 100%;
    }
</style>