import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ProfileView from '../views/Profile.vue'
import WebViewProfile from '@/views/WebViewProfile.vue'
import WebViewGroupTasks from '@/views/WebViewGroupTasks.vue'
import WebViewGroupTaskDetail from '@/views/WebViewGroupTaskDetail.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/profile',
    name: 'profile',
    component: ProfileView
  },
  {
    path: '/web-view-profile/:token',
    name: 'web_view_profile',
    component: WebViewProfile,
  },
  {
    path: '/web-view/group-tasks/:token',
    name: 'web_view_group_tasks',
    component: WebViewGroupTasks
  },
  {
    path: '/web-view/group-tasks/:id/:token',
    name: 'web_view_group_task_detail',
    component: WebViewGroupTaskDetail,
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
