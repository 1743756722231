<template>
    <div style="height: 100vh;background-color: #ECF0F8;">
        <div class="profile-header" id="activity-header">
            <h2><b>{{$t('groupMessageTitle')}}</b></h2>
        </div>
        <div style="background-color: #ECF0F8;width: 100%; height: auto; padding: 0px 8px 0px 8px;overflow-x: auto;" id="activity-container">

            <button class="join-sub-element" @click="openModal">
                {{ $t('groupTask').joinToTask}}
            </button>

            <ModalCust @close-modal="closeModal" v-if="showModal">
                <div class="form-group">
                    <label for="exampleInputEmail1">{{$t('groupTask').groupTaskCode}}</label>
                    <input v-model="groupTaskCode" type="text" class="form-control" id="groupTaskCode" :placeholder="$t('groupTask').enterCode">
                    <span style="color:red" v-if="errorMessage != ''">{{ errorMessage }}</span>
                </div><br>
                <button class="join-sub-element" @click="joinToGroup">
                    <div v-if="!groupTaskLoader">{{$t('groupTask').join}}</div>
                    <div v-else class="spinner-border text-light" role="status">
                        <span class="sr-only"></span>
                    </div>
                </button>
            </ModalCust>

            <GroupTaskItem
                v-for="groupTask in groupTasks"
                :groupTask="groupTask"
            >
            </GroupTaskItem>
        </div>
    </div>
</template>

<script>
    import ModalCust from '@/components/modals/ModalCustm'
    import GroupTaskItem from '@/components/tasks/GroupTaskItem'

    export default {
        name: 'WebViewGroupTasks',
        data() {
            return {
                groupTasks: [],
                colors: [
                    'rgba(255, 125, 125, 1)',
                    'rgba(18, 164, 237, 1)'
                ],
                showModal: false,
                groupTaskCode: '',
                errorMessage: '',
                groupTaskLoader: false,
            }
        },
        components: {
            ModalCust,
            GroupTaskItem
        },
        mounted () {
            let header = document.getElementById('activity-header');
            let container = document.getElementById('activity-container');

            container.style.paddingTop = (header.offsetHeight + 16) + 'px';
            this.getGroupTasks()
        },
        methods: {
            getToken() {
                return this.$route.params.token;
            },
            closeModal() {
                this.showModal = false;
                this.errorMessage = '';
                this.groupTaskCode = '';
            },
            openModal() {
                this.showModal = true;
            },
            async joinToGroup() {
                this.groupTaskLoader = true;
                this.errorMessage = '';
                let token = this.getToken();
                const currentVue = this;
                const config = {
                    headers: { Authorization: `Bearer ${token}` }
                }

                await axios.post('http://46.101.199.49/api/task/group/add',
                    {
                        code: this.groupTaskCode
                    },
                    config
                ).then(function (response) {
                    let data = response.data;
                    
                    if (data.status == 'fail') {
                        currentVue.errorMessage = currentVue.$t('groupTask').invalidCode;
                    } else {
                        currentVue.errorMessage = '';
                        currentVue.getGroupTasks();
                        currentVue.closeModal()
                    }

                    currentVue.groupTaskLoader = false;
                })
                .catch(error => {

                    currentVue.groupTaskLoader = false;
                    return ;
                })
            },
            async getGroupTasks() {
                let token = this.getToken();
                const currentVue = this;
                const config = {
                    headers: { Authorization: `Bearer ${token}` }
                }

                await axios.get('http://46.101.199.49/api/task/group?date=2024-07-13', config)
                    .then(response => {
                        currentVue.groupTasks = response.data;

                        return ;
                    })
                    .catch (error => {
                        return ;
                    })
            }
        }
    }
</script>

<style>
    .profile-header {
        background-color: white;
        padding: 16px 16px;
        width: 100%;
        position: fixed;
    }
    .join-sub-element {
        background-color: rgba(56, 67, 255, 1);
        border-radius: 40px;
        padding: 12px 16px;
        color: white;
        text-align: center;
        font-weight: 600;
        margin-bottom: 16px;
        border: 0;
        width: 100%;
    }
</style>